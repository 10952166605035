import {useEffect, useRef, useState} from "react";

function App() {
  const height = useRef(10);
  const [heightState, setHeightState] = useState(height.current);
  useEffect(() => {
    height.current = window.innerHeight;
    window.addEventListener('message', function(event) {

      if (event.data?.type === "resize-feed") {
        height.current = event.data?.height;
        setHeightState(event.data?.height);
      }
    });
    window.addEventListener("resize-feed", (e) => {
        height.current = e.detail.height;
    });
  }, []);

  return (
      <iframe
          title="Elberegner"
          style={{width: "100%", height: heightState, border: "none"}}
          src={"https://bt.elberegner.dk"}
      ></iframe>
  );
}

export default App;
